<template>
<div class="app flex-row align-items-center container-wrapper" :style="container_background_image">
    <div class="container col-10 col-sm-7 col-xl-5 pt-3 pb-3 pt-md-4 pb-md-4">
        <b-row class="m-0 justify-content-center shadow-2">
            <b-col class="p-0 bg-white">
                <q-card no-body class="p-4 full-height register-form" light>
                    <AppHeader class="header-lang no-border-bottom pl-3 pr-1 mb-0" :style="{position: 'relative', marginTop: '-1rem'}">
                        <switch-lang></switch-lang>
                    </AppHeader>
                    <div class="full-width navbar-brand text-center">
                        <img class="navbar-brand-full" :src="appLogo()" height="70" alt="Dynamic Studio Logo">
                    </div>
                    <b-form @submit.prevent="registerUser">
                        <p class="text-center mb-4 pb-4 font-weight-bold">{{ translate('create_account') }}</p>

                        <div class="row ml-0 mr-0 mb-1">
                            <div class="col-md pl-0 pr-xs-0">
                                <b-input-group>
                                    <!-- <label class="m-0">{{ translate('first_name') }}</label> -->
                                    <q-input light square dense :error-message="translate('first_name_is_required')" no-error-icon :error="submitted && $v.newUser.first_name.$error" color="primary" type="text" v-model="newUser.first_name" :placeholder="translate('first_name')">
                                        <template v-slot:append>
                                            <q-icon name="icon-user" />
                                        </template>
                                    </q-input>
                                </b-input-group>
                            </div>
                            <div class="col-md pr-0 pl-xs-0">
                                <b-input-group>
                                    <!-- <label class="m-0">{{ translate('last_name') }}</label> -->
                                    <q-input light square dense :error-message="translate('last_name_is_required')" no-error-icon :error="submitted && $v.newUser.last_name.$error" color="primary" type="text" v-model="newUser.last_name" :placeholder="translate('last_name')">
                                        <template v-slot:append>
                                            <q-icon name="icon-user" />
                                        </template>
                                    </q-input>
                                </b-input-group>
                            </div>
                        </div>

                        <div class="row ml-0 mr-0 mb-1">
                            <div class="col-md pl-0 pr-xs-0">
                                <b-input-group>
                                    <!-- <label class="m-0">{{ translate('email') }}</label> -->
                                    <q-input light square dense :error-message="translate('email_is_required')" no-error-icon :error="submitted && $v.newUser.email.$error" color="primary" type="text" v-model="newUser.email" :placeholder="translate('email')">
                                        <template v-slot:append>
                                            <q-icon name="icon-envelope" />
                                        </template>
                                    </q-input>
                                </b-input-group>
                            </div>
                            <div class="col-md pr-0 pl-xs-0">
                                <b-input-group>
                                    <!-- <label class="m-0">{{ translate('phone') }}</label> -->
                                    <q-input light square dense :error-message="translate('phone_is_required')" no-error-icon :error="submitted && $v.newUser.phone_number.$error" color="primary" type="text" v-model="newUser.phone_number" :placeholder="translate('phone')">
                                        <template v-slot:append>
                                            <q-icon name="icon-phone" />
                                        </template>
                                    </q-input>
                                </b-input-group>
                            </div>
                        </div>

                        <div class="row ml-0 mr-0 mb-1">
                            <div class="col-md pl-0 pr-xs-0">
                                <b-input-group>
                                    <!-- <label class="m-0">{{ translate('birthday') }}</label> -->
                                    <q-input @focus="$refs.qDateProxy.show()" @click="$refs.qDateProxy.show()" @input="$refs.qDateProxy.show()" light square color="primary" :placeholder="translate('birthday')" dense class="full-width" id="birth_date" v-model="newUser.birth_date" no-error-icon :error="submitted && $v.newUser.birth_date.$error" :error-message="translate('birth_date_is_required')">
                                        <template v-slot:append>
                                            <q-icon name="event" class="cursor-pointer pointer-events-none">
                                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                                    <q-date first-day-of-week="1" v-model="newUser.birth_date" mask="YYYY-MM-DD" @input="(val) => onUpdateDate(newUser.birth_date)" />
                                                </q-popup-proxy>
                                            </q-icon>
                                        </template>
                                    </q-input>
                                </b-input-group>
                            </div>
                            <div class="col-md pr-0 pl-xs-0">
                                <b-input-group>
                                    <!-- <label class="mb-0 mt-2 line-height-1">{{ translate('gender') }}</label> -->
                                    <q-select class="custom-dense-select" transition-show="fade" light square color="primary" dense v-model="newUser.gender" :options="gender_options" map-options use-input :placeholder="newUser.gender ? null : translate('gender')" no-error-icon :error="submitted && $v.newUser.gender.$error" :error-message="translate('gender_is_required')" />
                                </b-input-group>
                            </div>
                        </div>

                        <div class="row ml-0 mr-0 mb-1">
                            <div class="col-md pl-0 pr-xs-0">
                                <b-input-group>
                                    <!-- <label class="m-0">{{ translate('password') }}</label> -->
                                    <q-input @input="password_length = newUser.password.length" light square dense :error-message="translate('password_is_required')" no-error-icon :error="submitted && $v.newUser.password.$error" color="primary" type="password" v-model="newUser.password" :placeholder="translate('password') + (password_length > 0 ? ': ' + password_length + ' ' + translate('characters') : '')">
                                        <template v-slot:append>
                                            <q-icon name="icon-lock" />
                                        </template>
                                    </q-input>
                                    <div class="text-muted small" :class="{'mt-3': submitted && $v.newUser.password.$error}">
                                        {{ translate('please_enter_password_with_limitations') }}
                                    </div>
                                </b-input-group>
                            </div>
                            <div class="col-md pr-0 pl-xs-0">
                                <b-input-group>
                                    <!-- <label class="m-0">{{ translate('password_confirmation') }}</label> -->
                                    <q-input light square dense no-error-icon :error="submitted && $v.newUser.password_confirmation.$error" color="primary" type="password" v-model="newUser.password_confirmation" :placeholder="translate('password_confirmation')" :error-message="validateConfirmPwd()">
                                        <template v-slot:append>
                                            <q-icon name="icon-lock" />
                                        </template>
                                    </q-input>
                                </b-input-group>
                            </div>
                        </div>
                        <div class="row ml-0 mr-0 mb-1">
                            <div class="col-md p-0">
                                <q-checkbox dense v-model="newUser.receive_emails" color="primary">
                                    {{ translate('receive_emails_is_mandatory') }}
                                </q-checkbox>
                                <!-- tac: with_validation -->
                                <q-field ref="toggleTac" class="p-0 m-0" :value="newUser.tac" :rules="[tacRule]" borderless dense>
                                    <template v-slot:control>
                                        <q-checkbox style="margin-bottom: - 1rem;" dense v-model="newUser.tac" color="primary">
                                            {{ translate('terms_and_conditions_agreement').replace('%the_terms_and_conditions%', '') }}
                                            <router-link :to="{name: 'TermsAndConditions'}">{{ translate('the_terms_and_conditions') }}</router-link>
                                        </q-checkbox>
                                    </template>
                                </q-field>
                            </div>
                        </div>
                        <div class="text-center">
                            <q-btn class="full-width mt-2 mb-2 icon-right-leftspace" icon-right="send" color="info" square no-caps type="submit">{{ translate('register') }}</q-btn>
                        </div>
                        <div v-if="msg" :class="created ? 'alert-success border-top-green' : 'alert-danger border-top-red'" class="alert mt-4 pl-3">{{ msg }}</div>
                    </b-form>

                    <div class="full-width text-center">
                        <p class="mb-3 mt-2">
                            {{ translate('already_have_account_login') }}
                        </p>
                        <q-btn icon-right="login" color="dark" square no-caps :to="{name: 'Login'}" type="button" class="full-width no-text-decoration icon-right-leftspace">{{ translate('login') }}</q-btn>
                    </div>
                </q-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import {
    Header as AppHeader
} from '@coreui/vue'
import {
    required,
    email,
    minLength,
    sameAs
} from "vuelidate/lib/validators"
import LangNavbarAuth from './LangNavbarAuth'
import {
    eventBus
} from '../../main'

export default {
    name: 'Register',
    components: {
        AppHeader,
        'switch-lang': LangNavbarAuth
    },
    mounted: function () {
        eventBus.$on('current_language', (lang) => {
            this.setSelectedLanguage(lang)
        })
    },
    computed: {
        random_background: function () {
            var array = ['https://img5.goodfon.com/original/1920x1080/8/fd/sportivnaia-para-zal-trenazhernyi-zal-sport-tiaga-ganteli-pl.jpg', '/img/backgrounds/background_login.jpg']
            var random = this.$shuffle(array)

            return random[0]
        },
        container_background_image: function () {
            return false
            return 'background-image: url(' + this.random_background + ');'
        },
        gender_options: function () {
            return [{
                    value: 'f',
                    label: this.translate('gender_female')
                },
                {
                    value: 'm',
                    label: this.translate('gender_male')
                },
            ]
        }
    },
    data: function () {
        return {
            submitted: false,
            created: false,
            password_length: 0,
            newUser: {
                tac: false,
                first_name: null,
                last_name: null,
                email: null,
                phone_number: null,
                gender: null,
                password: null,
                birth_date: null,
                password_confirmation: null,
                receive_emails: false
            },
            msg: null,
            selected_language: 'ro'
        }
    },
    validations: {
        newUser: {
            tac: {
                required
            },
            phone_number: {
                required
            },
            birth_date: {
                required
            },
            gender: {
                required
            },
            first_name: {
                required
            },
            last_name: {
                required
            },
            email: {
                required,
                email
            },
            password: {
                required
            },
            password_confirmation: {
                required,
                sameAsPassword: sameAs('password')
            },
            receive_emails: {
                required,
            }
        }
    },
    methods: {
        onUpdateDate: function (newValue) {
            this.$nextTick(function () {
                this.$refs.qDateProxy.hide()
            })
        },
        tacRule: function (val) {
            return val || ''
        },
        validateTacRule: function () {
            this.$refs.toggleTac.validate()
        },
        validateConfirmPwd: function() {
            return !this.$v.newUser.password_confirmation.required ? this.translate('confirm_password_is_required') : this.translate('confirm_password_must_match')
        },
        setSelectedLanguage: function (lang) {
            this.selected_language = lang
        },
        registerUser: function () {
            this.submitted = true
            this.$v.$touch()
            this.validateTacRule()

            if (this.$v.$invalid) {
                return this.validateData()
            }

            if (this.newUser) {
                if (this.newUser.gender && this.newUser.gender.value) {
                    this.newUser.gender = this.newUser.gender.value
                }
                if (this.selected_language) {
                    this.newUser.lang = this.selected_language
                }
                axios.post(baseUrl + 'auth/register', this.newUser)
                    .then(response => {
                        var message = ''
                        if (response.data.status) {
                            this.msg = this.translate('check_your_email')
                            this.created = true
                        } else {
                            this.msg = this.translate(response.data.msg)
                        }
                    })
                    .catch(error => {
                        var msg = error.response.data.msg
                        if (msg != undefined) {
                            this.msg = msg
                        }
                    })
            }
        },
        validateData: function() {
            this.msg = null;

            if (this.$v.newUser.email.$invalid) {
                this.msg = this.translate('email_is_invalid')
            }

            return false
        }
    }
}
</script>
